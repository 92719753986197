<template>
    <b-navbar toggleable="md" type="" variant="">
        <div class="container">
            <b-navbar-brand to="/">
                <img :src="logo" alt="University and State Library Darmstadt"/>
            </b-navbar-brand>
            <!--
            <b-navbar-nav class="ml-auto">
                <b-nav-item href="#">
                    <i class="icon-user"></i> <span class="d-none d-md-inline">Log In</span>
                </b-nav-item>
            </b-navbar-nav>
            -->
        </div>
    </b-navbar>
</template>

<script>
    import image from '@/assets/images/ulb_logo_schriftzug_tuerkis_cmyk.png';

    export default {
        data() {
            return {
                logo: image
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "@/scss/custom-bootstrap";
@import "~bootstrap/scss/bootstrap";
    .navbar {
        font-family: $font-family-sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            height: 120px;
        }
    }

    .navbar-brand img {
        height: 50px;
        @include media-breakpoint-up(md) {
            height: 72px;
        }
    }

    i {
        @include media-breakpoint-up(md) {
            font-size: 22px;
            line-height: 25px;
        }
    }
</style>
